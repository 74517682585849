import React from 'react';
import {createRoot} from 'react-dom/client';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';
import * as Sentry from '@sentry/react';

import 'moment-timezone';

import {appTheme} from './Theme';
import {CssBaseline, ThemeProvider} from '@mui/material';
import RouteErrorView from './components/RouteErrorView/RouteErrorView';
import {MainView} from './components/MainView/MainView';

import '../assets/scss/app.scss';
import {BrowserTracing} from '@sentry/tracing';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  enabled: SENTRY_ENABLED as boolean,
});

const container = document.getElementById('App') as Element;

const reactRoot = createRoot(container, {
  onRecoverableError: error => {
    console.log(`onRecoverableError: ${error}`);
  },
});

const browserRouter = createBrowserRouter([
  {
    path: '/',
    element: <MainView />,
    children: [],
    errorElement: <RouteErrorView />,
  },
]);

const RootContainer = () => {
  return (
    <React.StrictMode>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <RouterProvider router={browserRouter} />
      </ThemeProvider>
    </React.StrictMode>
  );
};

reactRoot.render(<RootContainer />);
