/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Typography, Grid} from '@mui/material';

import {AuthViewHeader} from '../common/AuthViewHeader/AuthViewHeader';

export const MainView = () => {
  return (
    <>
      <AuthViewHeader enableSignUp={false} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '5rem',
          width: '100%',
        }}
      >
        <Grid
          container
          direction="column"
          sx={{
            width: {
              xs: '337px',
              sm: '337px',
              md: '337px',
              lg: '400px',
            },
            maxWidth: '400px',
            p: 0,
          }}
        >
          <Grid item>
            <Typography variant="h3" component="h2" fontWeight="fontWeightBold">
              Digistaff Analytics
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
